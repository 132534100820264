<template>
    <div>
        <v-alert type="error" v-if="validationError">
            {{ $t('views.login.login_error') }}
        </v-alert>

        <LoginForm ref="form" :loading.sync="loading" :validationError.sync="validationError" class="login-form">
            <template #submit>
                <v-btn block color="primary" depressed type="submit" :loading="loading" class="mb-12 mt-4">{{ $t('actions.login') }}</v-btn>
            </template>
        </LoginForm>

        <div class="text-center pb-4 caption-font-size caption-font-color">
            <span>{{ $t('globals.powered_by_cplug') }}</span>
        </div>
    </div>
</template>
<script>
import LoginForm from '@/components/forms/LoginForm'

export default {
    name: 'LoginView',
    components: {
        LoginForm,
    },
    props: {
        // Indica se o usuário acabou de fazer logout
        logout: Boolean,

        // Indica se o usuário foi redirecionado para fazer login
        // authNeeded: Boolean,

        // Indica se o usuário foi redirecionado para fazer login
        // reAuth: Boolean,

        // Indica se o token expirou
        tokenExpired: Boolean,
    },
    data: vm => ({
        loading: false,
        validationError: false,
        shouldShowAlerts: true,
    }),
    mounted() {
        // Depois de 5s os alertas somem
        setTimeout(() => {
            this.shouldShowAlerts = false
        }, 5000)
    },
}
</script>

<style lang="scss" scoped>
.login-form {
    max-width: 300px;
    margin: auto;
}
</style>