var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.validationError
        ? _c("v-alert", { attrs: { type: "error" } }, [
            _vm._v(" " + _vm._s(_vm.$t("views.login.login_error")) + " ")
          ])
        : _vm._e(),
      _c("LoginForm", {
        ref: "form",
        staticClass: "login-form",
        attrs: { loading: _vm.loading, validationError: _vm.validationError },
        on: {
          "update:loading": function($event) {
            _vm.loading = $event
          },
          "update:validationError": function($event) {
            _vm.validationError = $event
          },
          "update:validation-error": function($event) {
            _vm.validationError = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "submit",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    staticClass: "mb-12 mt-4",
                    attrs: {
                      block: "",
                      color: "primary",
                      depressed: "",
                      type: "submit",
                      loading: _vm.loading
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("actions.login")))]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "div",
        {
          staticClass: "text-center pb-4 caption-font-size caption-font-color"
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("globals.powered_by_cplug")))])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }