var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.isformValid,
        callback: function($$v) {
          _vm.isformValid = $$v
        },
        expression: "isformValid"
      }
    },
    [
      _c("cp-text-field", {
        attrs: {
          type: "email",
          label: _vm.$t("forms.LoginForm.email.label"),
          hint: _vm.$t("forms.LoginForm.email.hint"),
          placeholder: _vm.$t("forms.LoginForm.email.placeholder"),
          disabled: _vm.loading,
          required: ""
        },
        model: {
          value: _vm.formData.email,
          callback: function($$v) {
            _vm.$set(_vm.formData, "email", $$v)
          },
          expression: "formData.email"
        }
      }),
      _c("cp-text-field", {
        attrs: {
          type: "password",
          label: _vm.$t("forms.LoginForm.password.label"),
          hint: _vm.$t("forms.LoginForm.password.hint"),
          placeholder: _vm.$t("forms.LoginForm.password.placeholder"),
          disabled: _vm.loading,
          required: ""
        },
        on: {
          keyup: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.submit($event)
          }
        },
        model: {
          value: _vm.formData.password,
          callback: function($$v) {
            _vm.$set(_vm.formData, "password", $$v)
          },
          expression: "formData.password"
        }
      }),
      _c(
        "div",
        { staticClass: "float-right" },
        [
          _c(
            "router-link",
            {
              staticClass: "caption-font-size forgot-password",
              class: _vm.isLightTheme ? "forgot_pass_light_theme" : "",
              attrs: { to: { name: "auth.forgot_password" } }
            },
            [_vm._v(_vm._s(_vm.$t("forms.LoginForm.forgot_password")))]
          )
        ],
        1
      ),
      _c("div", { staticClass: "clear-fix" }),
      _vm._t("default"),
      _vm._t("submit")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }