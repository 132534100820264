<template>
    <v-form @submit.prevent="submit" lazy-validation v-model="isformValid">
        <cp-text-field
            v-model="formData.email"
            type="email"
            :label="$t('forms.LoginForm.email.label')"
            :hint="$t('forms.LoginForm.email.hint')"
            :placeholder="$t('forms.LoginForm.email.placeholder')"
            :disabled="loading"
            required
        />

        <cp-text-field
            v-model="formData.password"
            type="password"
            :label="$t('forms.LoginForm.password.label')"
            :hint="$t('forms.LoginForm.password.hint')"
            :placeholder="$t('forms.LoginForm.password.placeholder')"
            @keyup.enter="submit"
            :disabled="loading"
            required
        />

        <div class="float-right">
            <router-link :class="isLightTheme ? 'forgot_pass_light_theme': '' " class="caption-font-size forgot-password" :to="{ name: 'auth.forgot_password' }">{{ $t('forms.LoginForm.forgot_password') }}</router-link>
        </div>
        <div class="clear-fix"></div>

        <!-- Slot para campos adicionais -->
        <slot></slot>

        <!-- Slot para botão de submit -->
        <slot name="submit"></slot>

    </v-form>
</template>

<script>
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import RateColor            from '@/shared/mixins/RateColor'


export default {
    name: 'LoginForm',
    mixins: [ HasErrorHandlerMixin, RateColor ],
    props: {
        disabled: Boolean,
    },
    data: vm => ({
        formData: {
            email: '',
            password: '',
        },
        loading: false,
        validationError: false,
        isformValid: false,
    }),
    methods: {
        async submit() {
            this.validationError = false

            this.loading = true
            let res = await this.$store.dispatch('auth/login', this.formData)
                .catch(this.preErrorHandler)
            this.loading = false
        },

        preErrorHandler(e) {
            if (e && e.response && e.response.status == 422) {
                this.validationError = true
                return
            }

            this.errorHandler(e)
        },
    },
    watch: {
        loading(v) {
            this.$emit('update:loading', v)
        },
        validationError(v) {
            this.$emit('update:validationError', v)
        },
    },
}
</script>

<style scoped lang="scss">
.forgot_pass_light_theme {
    color: #212121 !important;
}

.forgot-password {
    color: var(--v-primary-base);
    text-decoration: none;
}
</style>